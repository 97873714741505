import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Banner from "../components/shop/Banner";
// import Products from "../components/shop/Products";
import Profile from "../components/shop/Profile";
import SearchBox from "../components/shop/SearchBox";
import { Container, Grid } from "@mui/material";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Shop() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <Banner />
      <Container>
        <Box sx={{ width: "100%", direction: "rtl", minHeight: "700px" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Grid container>
              <Grid item md={6}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab label=" فروشگاه" {...a11yProps(0)} />
                  <Tab label="محصولات" {...a11yProps(1)} />
                </Tabs>
              </Grid>
              <Grid item md={6} sx={{ display: { xs: "none", sm: "block" } }}>
                <SearchBox />
              </Grid>
            </Grid>
          </Box>
          <CustomTabPanel value={value} index={1}>
            <p>شما هنوز محصولی ثبت نکرده اید!</p>
            {/* <Products /> */}
          </CustomTabPanel>
          <CustomTabPanel value={value} index={0}>
            <Profile />
          </CustomTabPanel>
        </Box>
      </Container>
    </>
  );
}
