import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { ContactFormData } from "../types";
import {
  useDispatch,
  //  useSelector
} from "react-redux";
import {
  AppDispatch,
  // RootState
} from "../state/store";
import {
  startSubmit,
  submitSuccess,
  submitFailure,
} from "../state/contact/contactFormSlice";
import { submitContactForm } from "../services/api";

export default function Contact() {
  const dispatch = useDispatch<AppDispatch>();
  const [formData, setFormData] = useState<ContactFormData>({
    name: "",
    tel: "",
    email: "",
    comment: "",
  });
  const handleGoBack = () => {
    window.history.back();
  };
  // const { submitting, submitted, error } = useSelector(
  //   (state: RootState) => state.contact
  // );
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(startSubmit());
    try {
      await dispatch(submitContactForm(formData));
      dispatch(submitSuccess());
    } catch (error: any) {
      dispatch(submitFailure(error.message));
    }
  };

  return (
    <Container component="main" maxWidth="xs" style={{ direction: "rtl" }}>
      <CssBaseline />
      {/* Link to prev page */}
      <Box sx={{ marginLeft: 2 }}>
        <IconButton aria-label="delete" onClick={handleGoBack} sx={{}}>
          <ArrowBackIcon />
        </IconButton>
      </Box>
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Link href="/">
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
        </Link>
        <Typography component="h1" variant="h5">
          تماس با ما
        </Typography>
        <Box component="form" noValidate sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoComplete="family-name"
                name="name"
                fullWidth
                id="name"
                placeholder="نام و نام خانوادگی"
                autoFocus
                value={formData.name}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                type="number"
                fullWidth
                id="tel"
                placeholder="شماره تماس"
                name="tel"
                autoComplete="tel"
                value={formData.tel}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                type="email"
                fullWidth
                id="email"
                placeholder="ایمیل"
                name="email"
                autoComplete="email"
                value={formData.email}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="comment"
                placeholder="پیام"
                id="comment"
                autoComplete="comment"
                multiline
                rows={4}
                value={formData.comment}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            onClick={(e) => handleSubmit}
            sx={{ mt: 3, mb: 2 }}
          >
            ارسال
          </Button>
        </Box>
      </Box>
    </Container>
  );
}
