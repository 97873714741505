import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getShopProductsList } from "../../services/api";
import { ProductState, Product } from "../../types";

const initialState: ProductState = {
  productList: [],
  fetchingProducts: false,
  errorMessage: null,
};

const shopSlice = createSlice({
  name: "products",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        getShopProductsList.fulfilled,
        (state, action: PayloadAction<Product[]>) => {
          state.productList = action.payload;
          state.fetchingProducts = false;
        }
      )
      .addCase(getShopProductsList.pending, (state) => {
        state.fetchingProducts = true;
      })
      .addCase(getShopProductsList.rejected, (state) => {
        state.fetchingProducts = false;
        state.errorMessage = "Something went wrong...";
      });
  },
});

export default shopSlice.reducer;
