import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
// import file from "../../data/etemady/profile";

function Profile() {
  const [markdownContent, setMarkdownContent] = useState("");
  async function fetchMarkdown() {
    const response = await fetch(
      "https://raw.githubusercontent.com/leylakangari/yaztap/main/etemadi"
    );
    const markdownText = await response.text();
    return markdownText;
  }

  useEffect(() => {
    async function fetchMarkdownAndSetState() {
      try {
        const markdownText = await fetchMarkdown();
        setMarkdownContent(markdownText);
      } catch (error) {
        console.error("Error fetching Markdown:", error);
      }
    }

    fetchMarkdownAndSetState();
  }, []);

  return (
    <div>
      <ReactMarkdown>{markdownContent}</ReactMarkdown>
    </div>
  );
}
export default Profile;
