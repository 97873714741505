import React from "react";
import Slider from "react-slick";
// import { Avatar } from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Gallery = () => {
  const settings = {
    customPaging: function (i: number) {
      return (
        // <a href="#">
        <img
          alt="Remy Sharp"
          src={require(`../../assets/images/abstract0${i + 1}.jpg`)}
        />
        // </a>
      );
    },
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div>
      <Slider {...settings}>
        <div>
          <img src={require("../../assets/images/abstract01.jpg")} alt="" />
        </div>
        <div>
          <img src={require("../../assets/images/abstract02.jpg")} alt="" />
        </div>
      </Slider>
    </div>
  );
};

export default Gallery;
