import { createTheme } from "@mui/material/styles";
// import { deepPurple } from "@mui/material/colors";
import BYekan from "../../assets/font/BYekan.woff";

export const Theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'BYekan';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('BYekan'), local('BYekan'), url(${BYekan}) format('woff');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: "#565c64",
          "&:hover": {
            color: "#583bf7",
          },
        },
      },
    },
  },
  typography: {
    fontFamily: "Yekan, Arial",
    body1: { color: "#132339" },
    subtitle1: { color: "#565c64", fontSize: 13 },
  },

  palette: {
    background: {
      default: "red",
    },
    primary: {
      main: "#583bf7",
    },
    secondary: {
      main: "#11cb5f",
    },
  },
});
