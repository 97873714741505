import * as React from "react";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import "./footer.css";

// const preventDefault = (event: React.SyntheticEvent) => event.preventDefault();

export default function Footer() {
  return (
    <Box
      style={{
        // position: "fixed",
        bottom: 10,
        // color: "#565c64",
        textAlign: "center",
        width: "100%",
      }}
    >
      <Link href="/privacy" variant="subtitle1" underline="hover">
        قوانین حریم‌خصوصی
      </Link>
      <span> - </span>
      <Link href="/about" variant="subtitle1" underline="hover">
        شرایط یازتاپ
      </Link>
      <span> - </span>
      <Link href="/contact-us" variant="subtitle1" underline="hover">
        تماس با ما
      </Link>
      <Typography variant="subtitle1">
        © YazTap {new Date().getFullYear()}. All Rights Reserved.
      </Typography>
    </Box>
  );
}
