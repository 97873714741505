import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
// import { styled } from "@mui/material/styles";
// import Paper from "@mui/material/Paper";
import SearchBox from "../components/SearchBox";
import Grid from "@mui/material/Grid";
import Header from "../components/layout/header";
import Logo from "../assets/images/yaztap_logo.svg";

function Home() {
  // const Item = styled(Paper)(({ theme }) => ({
  //   backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  //   ...theme.typography.body2,
  //   padding: theme.spacing(1),
  //   textAlign: "center",
  //   color: theme.palette.text.secondary,
  // }));
  return (
    <>
      <Container>
        <Header />
        <Box sx={{ flexGrow: 1 }}>
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ minHeight: "40vh" }}
          >
            <Grid
              container
              item
              spacing={3}
              xs={3}
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                item
                xs={6}
                md={3}
                justifyContent="center"
                alignItems="center"
              >
                <img
                  alt="yaztap"
                  src={Logo}
                  style={{
                    width: "100%",
                    paddingBottom: "2vh",
                    // filter:
                    //   "invert(88%) sepia(21%) saturate(935%) hue-rotate(123deg) brightness(85%) contrast(97%)",
                  }}
                />
              </Grid>
            </Grid>

            <Grid container item justifyContent="center" alignItems="center">
              <Grid item xs={12} md={6}>
                <SearchBox />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
}

export default Home;
