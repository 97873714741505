import React from "react";
import { Button, Container, Box } from "@mui/material";

const Header = () => {
  return (
    <Container sx={{ mt: 5 }}>
      <Box display="flex" justifyContent="flex-end">
        <Button variant="contained" href="/contact-us">
          ارتباط با ما
        </Button>
      </Box>
    </Container>
  );
};

export default Header;
