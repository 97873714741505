import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";

function SearchBox() {
  const [search, setSearch] = useState("");
  // const [keyword, setKeyword] = useState<string>("یاز تاپ");
  const navigate = useNavigate();
  const location = useLocation();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };
  function handleInputKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSubmit();
    }
  }

  const handleSubmit = () => {
    navigate(`/results?keyword=${search}`);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const searchValue = searchParams.get("keyword");
    if (
      searchValue !== undefined &&
      searchValue !== null &&
      searchValue !== ""
    ) {
      setSearch(searchValue);
    }
  }, [location.search]);
  return (
    <Paper
      component="form"
      sx={{
        p: "2px 10px",
        display: "flex",
        alignItems: "center",
        borderRadius: "50px",
        direction: "rtl",
      }}
    >
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="یاز تاپ"
        inputProps={{ "aria-label": "یاز تاپ" }}
        value={search}
        onChange={handleChange}
        onKeyPress={handleInputKeyPress}
      />
      <IconButton
        type="button"
        sx={{ p: "10px" }}
        aria-label="search"
        onClick={handleSubmit}
      >
        <SearchIcon />
      </IconButton>
    </Paper>
  );
}

export default SearchBox;
