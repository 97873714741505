// ProductDetail.js
import React from "react";
// import { useParams } from "react-router-dom";
import Gallery from "../components/shop/Gallery";
import DetailDsc from "../components/shop/DetailDsc";

import {
  Container,
  // Typography,
  // Card,
  // CardContent,
  // CardMedia,
  Grid,
  Box,
} from "@mui/material";

const ProductDetail = () => {
  // Use the useParams hook to get the product ID from the URL
  // const { productId } = useParams();

  // Replace the following data with actual product details fetched from an API or other source
  // const product = {
  //   id: productId,
  //   name: "Product Name",
  //   description: "Product Description",
  //   price: "$19.99",
  //   imageUrl: "https://example.com/product-image.jpg",
  // };

  return (
    <Container component="section" maxWidth={"lg"}>
      <Grid container spacing={2} sx={{ direction: "rtl" }}>
        <Grid item md={6} xs={12} sx={{ marginTop: "25px" }}>
          <Gallery />
        </Grid>
        <Grid item md={6} xs={12} sx={{ marginTop: "25px" }}>
          <Box sx={{ padding: { xs: 0, md: "25px" } }}>
            <DetailDsc />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ProductDetail;
