import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import SearchBox from "./SearchBox";

// import { deepPurple } from "@mui/material/colors";

const Banner = () => {
  return (
    <Box
      sx={{
        direction: "rtl",
        bgcolor: "#d10f0f",
        paddingTop: "10px",
        paddingBottom: "10px",
      }}
    >
      <Container>
        <Grid container spacing={2}>
          <Grid item>
            <Avatar
              alt="خدمات فنی اطمینان سرویس"
              src="/static/images/avatar/1.jpg"
              sx={{ bgcolor: "#fff", width: 56, height: 56 }}
            />
          </Grid>
          <Grid item>
            <Typography variant="h5" sx={{ color: "#fff" }}>
              خدمات فنی اطمینان سرویس
            </Typography>
            <Typography variant="body2" sx={{ color: "#fff" }}>
              نصب و تعمیر هود اجاق گاز فر
            </Typography>
          </Grid>
        </Grid>
        <Box sx={{ display: { xs: "block", sm: "none" } }}>
          <SearchBox />
        </Box>
      </Container>
    </Box>
  );
};

export default Banner;
