import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../state/store";
import { getShopProductsList } from "../../services/api";

export default function ShopSearchBox() {
  const { id } = useParams<{ id: any }>();
  const dispatch = useDispatch<AppDispatch>();
  const [searchKey, setSearchKey] = useState("");

  //call search buy keyword in shop api
  const getSearchInShop = () => {
    dispatch(getShopProductsList({ retailerId: id, keyword: searchKey }));
  };
  
  //submit for with enter key
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      getSearchInShop();
    }
  };
  return (
    <Paper
      component="form"
      sx={{ p: "2px 4px", display: "flex", marginTop: "10px" }}
    >
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="جستجو در فروشگاه"
        inputProps={{ "aria-label": "جستجو در فروشگاه" }}
        onChange={(e) => setSearchKey(e.target.value)}
        onKeyDown={handleKeyDown}
      />
      <IconButton
        type="button"
        sx={{ p: "10px" }}
        aria-label="جستجو"
        onClick={getSearchInShop}
      >
        <SearchIcon />
      </IconButton>
    </Paper>
  );
}
