import { configureStore } from "@reduxjs/toolkit";
import searchReducer from "./search/searchSlice";
import contactReducer from "./contact/contactFormSlice";
import shopReducer from "./shop/shopSlice";

export const store = configureStore({
  reducer: {
    search: searchReducer,
    contact: contactReducer,
    shop: shopReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
