import React from "react";
import "./assets/global.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import Footer from "./components/layout/footer/Footer";
import { Theme } from "./components/layout/Theme";
import Home from "./pages";
import Results from "./pages/results";
import About from "./pages/about";
import Privacy from "./pages/privacy";
import Contact from "./pages/contact";
import Shop from "./pages/shop";
import ProductDetail from "./pages/productDetail";

function App() {
  return (
    <ThemeProvider theme={Theme}>
      <>
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/results" element={<Results />} />
            <Route path="/about" element={<About />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/contact-us" element={<Contact />} />
            <Route path="/shop/:id" element={<Shop />} />
            <Route path="/product-detail" element={<ProductDetail />} />
          </Routes>
        </Router>
        <Footer />
      </>
    </ThemeProvider>
  );
}

export default App;
