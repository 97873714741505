import React, { useEffect } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import { useLocation } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import { fetchSearchByKey } from "../services/api";
import { AppDispatch, RootState } from "../state/store";
import Link from "@mui/material/Link";

function SearchResualtList() {
  const dispatch = useDispatch<AppDispatch>();
  const state = useSelector((state: RootState) => state.search);
  const searchList = state.searchList;

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const searchValue = searchParams.get("keyword");

  useEffect(() => {
    dispatch(fetchSearchByKey(searchValue));
  }, [dispatch, searchValue]);

  return (
    <>
      {state.fetchingSearch && <CircularProgress />}
      {state.errorMessage && (
        <Alert severity="error">{state.errorMessage}</Alert>
      )}
      <List
        sx={{
          width: "100%",
          direction: "rtl",
          textAlign: "right",
          paddingTop: "40px",
        }}
      >
        {searchList?.map((searchResualt: any, index) => (
          <div key={index}>
            <Link href={`#${searchResualt.id}`} underline="none">
              <ListItem sx={{ textAlign: "right" }}>
                <ListItemText
                  primary={
                    <React.Fragment>
                      <Typography
                        sx={{ display: "inline" }}
                        component="h3"
                        variant="body1"
                        color="text.primary"
                      >
                        {`${searchResualt.category} ${searchResualt.title} `}
                      </Typography>
                    </React.Fragment>
                  }
                  secondary={
                    <React.Fragment>
                      {searchResualt.address} {searchResualt.mall} 
                     {searchResualt.no !== 0 ? <span> پلاک {searchResualt.no}</span> : ""}
                      {searchResualt.tel[0] ? (
                        <>
                          <br />
                          شماره تماس: {searchResualt.tel[0]}
                        </>
                      ) : (
                        ""
                      )}
                      {searchResualt.description ? (
                        <>
                          <br /> {searchResualt.description}
                        </>
                      ) : (
                        ""
                      )}
                    </React.Fragment>
                  }
                />
              </ListItem>
            </Link>
            <Divider component="li" />
          </div>
        ))}
      </List>
    </>
  );
}

export default SearchResualtList;
