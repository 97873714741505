import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import SearchResualt from "../components/SearchResult";
import SearchBox from "../components/SearchBox";
import Logo from "../assets/images/yaztap_logo.svg";

function resualts() {
  return (
    <Container
      sx={{
        backgroundColor: "#fff",
        paddingTop: "20px",
        direction: "rtl",
        minHeight: "100vh",
      }}
    >
      <Grid container>
        <Grid item xs={5} md={2} spacing={4}>
          <img
            alt="yaztap"
            src={Logo}
            style={{
              width: "70%",
              paddingTop: "10px",
            }}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <SearchBox />
        </Grid>
      </Grid>
      <SearchResualt />
    </Container>
  );
}

export default resualts;
