import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// import { submitContactForm } from "../../services/api";
import { ContactState, ContactFormData } from "../../types";

const initialState: ContactState = {
    formData: null,
    submitting: false,
    submitted: false,
    error: null,
  };

  export const contactSlice = createSlice({
    name: 'contact',
    initialState,
    reducers: {
      setFormData: (state, action: PayloadAction<ContactFormData>) => {
        state.formData = action.payload;
      },
      startSubmit: (state) => {
        state.submitting = true;
        state.error = null;
      },
      submitSuccess: (state) => {
        state.submitting = false;
        state.submitted = true;
        state.formData = null;
      },
      submitFailure: (state, action: PayloadAction<string>) => {
        state.submitting = false;
        state.error = action.payload;
      },
    },
  });

  export const {
    setFormData,
    startSubmit,
    submitSuccess,
    submitFailure,
  } = contactSlice.actions;
  
  export default contactSlice.reducer;
