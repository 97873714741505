import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const detailDsc = () => {
  return (
    <Box>
      <Typography variant="h5">لباس مجلسی کوتاه</Typography>
      <Typography variant="body2">
        sidsudi uidudisiud usidsud iduidusiyfieufyhddkifuefis
      </Typography>
    </Box>
  );
};

export default detailDsc;
