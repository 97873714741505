import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  Search,
  ContactFormData,
  GetShopProductsListParams,
} from "../../types";
const baseUrl = process.env.REACT_APP_API_BACE_URL;

//Fetch search resualt by keyword
export const fetchSearchByKey = createAsyncThunk(
  "searches/fetch",

  async (keyword: string | null) => {
    if (keyword !== "") {
      const response = await fetch(`${baseUrl}/occupier?phrase=${keyword}`);
      const data: Search[] = await response.json();
      return data;
    } else {
      const response = await fetch(`${baseUrl}/occupiers`);
      const data: Search[] = await response.json();
      return data;
    }
  }
);
//submit customer contact form data
export const submitContactForm = createAsyncThunk(
  "contact/submitForm",
  async (formData: ContactFormData, thunkAPI) => {
    try {
      const response = await axios.post(`${baseUrl}/contact`, formData);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// get shop products list
export const getShopProductsList = createAsyncThunk(
  "shop/getProductsList",
  async ({ retailerId, keyword }: GetShopProductsListParams) => {
    const url = keyword
      ? `https://fakestoreapi.com/products?phrase=${encodeURIComponent(
          keyword
        )}` // TODO retailer/{retailerId}/products?phrase=something-here
      : `https://fakestoreapi.com/products`; // TODO /retailer/{retailerId}/products
    const response = await axios.get(url);
    return response.data;
  }
);
