import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchSearchByKey } from "../../services/api";
import { SearchState, Search } from "../../types";

const initialState: SearchState = {
  searchList: [],
  fetchingSearch: false,
  errorMessage: null,
};

const searchSlice = createSlice({
  name: "searches",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchSearchByKey.fulfilled,
        (state, action: PayloadAction<Search[]>) => {
          state.searchList = action.payload;
          state.fetchingSearch = false;
        }
      )
      .addCase(fetchSearchByKey.pending, (state) => {
        state.fetchingSearch = true;
      })
      .addCase(fetchSearchByKey.rejected, (state) => {
        state.fetchingSearch = false;
        state.errorMessage = "Something went wrong...";
      });
  },
});

export default searchSlice.reducer;
